import React, { useState, useEffect } from "react";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import { renderText } from "./text";

function App() {
  const [Cnpj, setCnpj] = useState("");
  const [mask, setMask] = useState("");
  const [message, setMessage] = useState(undefined);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urltoken = urlParams.get("token");

  async function sendAgreement() {
    if (!Cnpj) return null;

    var obj = {
      method: "POST",

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + urltoken,
      },
      body: JSON.stringify({
        cnpj: Cnpj,
      }),
    };

    const response = await fetch(
      "https://api.gerentesdigitais.com/1.0/sharedbudgets/UserAgreements",
      obj
    );

    if (response.status !== 200) {
      const data = await response.json();
      data.error === "INVALID_CNPJ"
        ? setMessage("CNPJ Inválido. Confira se o número está correto.")
        : setMessage("Não foi possível aceitar o termo.");
    } else {
      setMessage("Termo aceito com sucesso!");
    }
  }

  return (
    <>
      <div className="">{renderText()}</div>
      <div className="wrapper">
        <div className="">
          <CpfCnpj
            className="cnpj"
            placeholder="Informe seu CNPJ"
            value={Cnpj}
            onChange={(event, type) => {
              setCnpj(event.target.value);
              setMask(type === "CNPJ");
              setMessage(undefined);
            }}
          />
        </div>
        <div
          onClick={() => sendAgreement()}
          className={!Cnpj ? "button disabled" : "button"}
        >
          Li e aceito
        </div>
      </div>
      <div className="message">{message}</div>
    </>
  );
}

export default App;
