const renderText = () => {
  return (
    <div className="textWrapper">
      <div className="titleWrapper">
        <h2>TERMO DE ADESÃO</h2>
        <h4>CONTA DE ANÚNCIOS COMPARTILHADA</h4>
        <div>Atualizado em 16 de junho de 2021</div>
        <div>
          Por meio do presente instrumento são estabelecidos os termos e as
          condições gerais de uso da Conta de Anúncios compartilhada (“Termo de
          Adesão”).
        </div>
      </div>

      <ul>
        <li>1. DEFINIÇÕES</li>
        <li>
          1.1. Os termos e as expressões abaixo, quando iniciados em letra
          maiúscula neste Termo de Adesão, no singular ou no plural, no
          masculino ou no feminino, terão os significados que lhes são indicados
          ao longo do Contrato ou abaixo: “Operadora de mídia” se refere à
          BORNLOGIC TECNOLOGIA S.A, empresa de tecnologia com foco na criação de
          inovações para a automação e escala de campanhas digitais de mídia
          paga, devidamente qualificada no “Contrato”, na posição de Contratada.
          “Tomadora dos Serviços” se refere à Empresa devidamente qualificada no
          Contrato, na posição de Contratante. “Permissionário” se refere à(s)
          empresa(s) franqueada(s), filial(is) ou empresa(s) representante(s) da
          indústria, previamente listada(s) pela Tomadora de Serviços, conforme
          condições definidas no “Contrato”, submetidas às operações previstas
          neste Termo de Adesão. “Operação de mídia” refere-se ao processo de
          captação, distribuição de recursos e pagamento de Mídias Sociais
          (repasse de verba), por meio de uma conta de anúncios compartilhada
          entre a Tomadora de Serviços e os Permissionários e gerida pela
          Operadora de Mídia; “Mídias Sociais” refere-se a todo e qualquer canal
          online que permita o relacionamento e compartilhamento de conteúdo
          entre usuários, incluindo, mas não se limitando, ao Facebook e ao
          Instagram;
        </li>
      </ul>
      <ul>
        <li>2. DO OBJETO</li>
        <li>
          2.1. DEFINIÇÃO. A Conta Compartilhada é uma solução operacional
          voltada para a reunião, em uma única conta, de toda a verba
          direcionada para financiamento de mídia da Tomadora de Serviços e dos
          Permissionários.
        </li>
        <li>
          2.2. FINALIDADE. A solução permite o aprimoramento da gestão
          operacional da compra de anúncios publicitários, sob a perspectiva do
          controle e da transparência dos fluxos de pagamento, além de garantir
          mais segurança e simplicidade ao processo de repasse de valores para
          Mídias Sociais.
        </li>
        <li>2.3. PROCEDIMENTO</li>
        <li>
          2.3.1 CONDIÇÃO PARA USO DA CONTA COMPARTILHADA. É indispensável à
          operacionalização da solução, a existência de crédito em Conta em
          momento anterior à compra de anúncios. Caso não haja valores
          creditados na conta compartilhada da plataforma gerente digitais, nos
          termos da cláusula
        </li>
        <li>
          2.3.2, a funcionalidade descrita na cláusula 2.1 não será utilizada.
        </li>
        <li>
          2.3.1.1. Os valores em trânsito na conta compartilhada, indicados no
          caput, em nenhuma circunstância corresponderão ou integrarão o preço
          da prestação de serviços da Operadora de Mídia.
        </li>
        <li>
          2.3.1.2. A Operadora de Mídia atua tão somente por conta e ordem da
          Tomadora de Serviços para quitação das compras de anúncio junto às
          Mídias Sociais.
        </li>
        <li>
          2.3.2 DEPÓSITO NA CONTA COMPARTILHADA. Os depósitos deverão ser feitos
          pelas Permissionárias e pela Tomadora de Serviços até o dia 20 de cada
          mês, para que o respectivo crédito seja disponibilizado no mês
          subsequente ao depósito.
        </li>
        <li>
          2.3.3 COMPRA DE ANÚNCIOS. As compras poderão seguir dois fluxos
          distintos, a depender da existência ou não de linha de crédito para a
          Tomadora dos serviços. As compras de Tomadores de Serviço sem linha de
          crédito deverão ser previamente avaliadas e aprovadas, antes da
          execução da ordem de compra.
        </li>
        <li>
          2.3.4 DISTRIBUIÇÃO DAS QUOTAS DE ANÚNCIO. É responsabilidade da
          Operadora de Mídia a apuração dos responsáveis pelos depósitos, bem
          como dos períodos e valores correspondentes e, na sequência, o
          direcionamento da verba de mídia para a “Plataforma Gerentes
          Digitais”.
        </li>
        <li>
          2.3.5 EMISSÃO DE NOTA FISCAL. A nota fiscal de mídia correspondente ao
          valor depositado será emitida em nome da Tomadora de Serviços e "aos
          cuidados da” Operadora de Mídia. A nota fiscal será entregue à
          Tomadora de Serviços até o dia 25 do mesmo mês do depósito.
        </li>
      </ul>
      <ul>
        <li>3. DAS RESPONSABILIDADES</li>
        <li>
          3.1. DA RESPONSABILIDADE DA TOMADORA DOS SERVIÇOS E DAS
          PERMISSIONÁRIAS. A Tomadora de Serviços e as Permissionárias estão
          cientes e de acordo que ao assinar o presente Termo de Aceitação estão
          automaticamente aderindo aos termos e condições de compra determinados
          pela Mídia Social anunciante, a exemplo dos termos e condições do
          facebook, disponíveis em:
          https://business.facebook.com/legal/sequential_invoicing_terms/.
        </li>
        <li>
          3.2. DA RESPONSABILIDADE DA OPERADORA DE MÍDIA. Além das
          responsabilidades especificamente atribuídas à Operadora de Mídia ao
          longo do presente Termo de Adesão, é de responsabilidade da Bornlogic,
          sempre que a Tomadora de Serviços opere com linha de crédito, a
          emissão de Recibos e Notas Fiscais referente a operação de mídia, além
          do pagamento da Nota Fiscal da Mídia Social ao final do período.
        </li>
      </ul>
      <ul>
        <li>4. DA VIGÊNCIA </li>
        <li>
          4.1. Este termo terá vigência pelo prazo que perdurar o “Contrato”,
          salvo na hipótese de incidência da cláusula 4.2.
        </li>
        <li>
          4.1.1. Caso o Contrato de Prestação de Serviços indicado no caput seja
          rescindido, independente da razão, este Termo de Adesão estará
          imediatamente rescindido, sem ônus para quaisquer das partes.
        </li>
        <li>
          4.2. As Partes poderão manifestar seu desinteresse na continuidade
          deste Termo de Adesão, mediante notificação, por escrito, às outras
          Partes, com 90 (noventa) dias de antecedência.
        </li>
        <li>
          4.3. Qualquer alteração referente a este Termo de Adesão deverá ser
          objeto de termo aditivo a ser assinado pelas partes.
        </li>
      </ul>
      <ul>
        <li>5. DAS DISPOSIÇÕES GERAIS</li>
        <li>
          5.1. As Partes comprometem-se a adotar todas as medidas necessárias
          para assegurar a observância à Lei nº 12.965/2014 (“Marco Civil da
          Internet”), e o seu Decreto Regulamentador nº 8.771/16, principalmente
          no que tange à proteção de registros, dados pessoais e comunicações
          privadas, e à Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados”),
          em conformidade com as obrigações assumidas no “Contrato”.{" "}
        </li>
        <li>
          5.2. As relações jurídicas estabelecidas neste Termo de Adesão são
          celebradas em caráter irrevogável e irretratável, ressalvadas as
          exceções da cláusula 4, obrigando as Partes e seus sucessores, seja
          qual for o título da sucessão. A eventual tolerância por qualquer das
          Partes quanto ao inexato ou impontual cumprimento das obrigações da
          outra Parte valerá tão somente de forma isolada, não constituindo
          renúncia ou novação.
        </li>
        <li>
          5.3. O Termo de Adesão será regido e interpretado de acordo com a
          legislação da República Federativa do Brasil. No caso de qualquer
          reivindicação ou controvérsia decorrente do Contrato, ou a eles
          relacionada, ou, ainda, resultante de seu inadimplemento, as Partes
          elegem desde já o foro da Comarca de São Paulo, Estado de São Paulo,
          para a solução da reivindicação ou controvérsia, com a exclusão de
          qualquer outro, por mais privilegiado que seja.
        </li>
      </ul>
    </div>
  );
};

export { renderText };
